import {
  Autocomplete,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material"
import axios from "axios"
import { useRouter } from "next/router"
import React, { useContext, useState } from "react"
import { toast } from "react-toastify"
import uuid from "react-uuid"
import styled from "styled-components"
import { signIn as SignIntoProvider, useSession } from "next-auth/react"
import translate from "@/utils/translation"
import TagManager from "react-gtm-module"
import handleError from "@/lib/errors/handleError"
import { getCookie } from "cookies-next"
import countries from "@/utils/countries"
import { FaLock } from "react-icons/fa"
import { MuiPhone } from "@/components/common/phoneInput/phoneInput"
import { fetchDiscount, RegisterUser } from "@/lib/strapi/userServices"
import posthog from "posthog-js"

const RegisterForm = ({ account, setAccount, setRegisterModal }) => {
  const Router = useRouter()

  const [errors, setErrors] = useState({})

  const { data: session } = useSession()

  const translation = {
    auth_required: translate("auth_validation_required"),
    auth_email: translate("login_label_error_email"),
    auth_password: translate("login_label_error_password"),
    auth_validation_already_exist: translate("auth_validation_already_exist"),
    auth_validation_error: translate("auth_validation_error"),
    auth_new_account_succesfull: translate("auth_new_account_succesfull"),
    auth_agree_terms_1: translate("auth_agree_terms_1"),
    auth_new_account_bonus: translate("auth_new_account_bonus"),
    auth_agree_terms_2: translate("auth_agree_terms_2"),
  }

  const validate = () => {
    let temp = {}

    temp.email =
      account.email.length !== 0 &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(account.email)
        ? ""
        : translation.auth_email

    temp.password =
      account?.password?.length > 8 ? "" : translation.auth_password

    temp.firstName =
      account?.firstName?.length !== 0 ? "" : translation.auth_required

    temp.lastName =
      account?.lastName?.length !== 0 ? "" : translation.auth_required

    temp.phone =
      account?.phone && /^\+?[1-9]\d{7,14}$/.test(account.phone)
        ? ""
        : translation.auth_required

    setErrors({
      ...temp,
    })

    return Object.values(temp)
  }

  const handleAccount = async () => {
    try {
      setAccount({ ...account, loading: true })

      const errorsFound = validate()

      if (errorsFound.some(item => item?.length > 0)) {
        setAccount({
          ...account,
          loading: false,
        })

        toast.error(translation.auth_required)

        return
      }

      const uid = uuid()

      const referralToken = uuid().substr(0, 7).replace(/-/g, "").toUpperCase()

      var newUser = {
        uid: uid,
        email: account.email,
        password: account.password,
        username: account.email,
        referralToken: referralToken,
        affiliateId: account?.click_id,
        profile: {
          address: {
            country: account?.country,
          },
          firstName: account?.firstName,
          lastName: account?.lastName,
          notifications: true,
          locale: Router?.locale,
          phone: account?.phone,
        },
        accountType: "buyer",
      }

      const createdUser = await axios.post(
        `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/auth/local/register`,
        newUser
      )

      const token = account?.token || localStorage?.getItem("referral")

      if (token) {
        const bonus = await fetchDiscount(token)

        if (bonus?.type === "discount") {
          const discountObject = {
            user: createdUser?.data?.user?.id,
            discount: bonus?.code,
            id: bonus?.id,
          }

          await axios.post(`/api/discounts`, discountObject).catch(err => {
            handleError(err)
          })
        } else if (bonus?.type === "referral") {
          const referralObject = {
            user: createdUser?.data?.user?.id,
            referral: account?.token || referral || "",
            type: "referee",
          }

          await axios.post(`/api/referrals`, referralObject).catch(err => {
            handleError(err)
          })
        }
      }

      TagManager.dataLayer({
        dataLayer: {
          event: "sign_up",
          name: account.firstName + " " + account.lastName,
          email: account.email,
          phone: account.phone,
          uid: uid,
        },
      })

      const fbp = getCookie("_fbp") ? getCookie("_fbp") : null
      const fbc = getCookie("_fbc") ? getCookie("_fbc") : getCookie("_fbclid")

      newUser.fbp = fbp
      newUser.fbc = fbc
      newUser.external_id = uid
      newUser.referral = localStorage.getItem("referral")
        ? localStorage.getItem("referral")
        : null

      const ip = await fetch("https://api.ipify.org?format=json")
        .then(response => response.json())
        .then(data => data.ip)
        .catch(() => null)

      import("@bettercart/react-facebook-pixel")
        .then(x => x.default)
        .then(ReactPixel => {
          ReactPixel.track(
            "CompleteRegistration",
            {
              currency: "EUR",
              external_id: uid ? uid : null,
              fbp: fbp ? fbp : null,
              fbc: fbc ? fbc : null,
              fn: account.firstName,
              ln: account.lastName,
              em: account.email,
              uid: uid ? uid : null,
              ph: account.phone,
              client_ip_address: ip,
              client_user_agent: window?.navigator?.userAgent,
            },
            { eventID: 100 }
          )
        })
        .catch(err => {
          handleError(err)
        })

      posthog.identify(account?.email, {
        firstName: account?.firstName,
        lastName: account?.lastName,
        phone: account?.phone,
      })

      posthog.capture("sign_up", {
        email: account?.email,
        firstName: account?.firstName,
        lastName: account?.lastName,
        phone: account?.phone,
        uid: uid,
        referral: localStorage.getItem("referral")
          ? localStorage.getItem("referral")
          : null,
        external_id: uid,
        fbp: fbp,
        fbc: fbc,
        client_ip_address: ip,
        client_user_agent: window?.navigator?.userAgent,
      })

      try {
        await axios.post(`/api/users/`, newUser)
      } catch (err) {
        handleError(err)
      }

      toast.success(translation.auth_new_account_succesfull)

      await SignIntoProvider("credentials", {
        username: account.email,
        password: account.password,
        redirect: false,
      }).then(res => {
        if (res?.status === 200) {
          //localStorage.removeItem("referral")

          if (account?.token) {
            setTimeout(() => {
              toast.success(`${translation.auth_new_account_bonus} 🚀`, {
                // Customize the progress bar with inline styles
                progressStyle: {
                  background:
                    "linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)",
                },
              })
            }, 1000)
          }
        } else {
          toast.error(translation.auth_validation_error)
        }
      })

      if (setRegisterModal) {
        setRegisterModal(false)
      }

      setAccount({ ...account, loading: false, step: "confirm" })
    } catch (err) {
      handleError(err)

      setAccount({
        ...account,
        loading: false,
      })
    }
  }

  const handleCountryChange = (event, newValue) => {
    setAccount({
      ...account,
      country: newValue?.code ?? "",
    })
  }

  if (account.loading || session?.profile) {
    return (
      <div className="text-center w-100 p-5">
        <CircularProgress size={36} />
      </div>
    )
  }

  return (
    <>
      <Form className="bg-white rounded form ">
        {Router.pathname.includes("auth") ? (
          <div className="">
            <div className="d-flex">
              <h1>{translate("auth_register_welcome")}</h1>
              <FaLock
                className="mt-2 ms-2"
                size={12}
                color="rgb(0, 166, 147)"
              />
            </div>
            <p className="disclaimer">{translate("auth_register_welcome_2")}</p>
          </div>
        ) : (
          <>
            <h1 className="mt-3 mb-2">
              {translate("auth_register_title_widget_2")}
            </h1>
            <p className="details">{translate("auth_register_welcome_2")}</p>
          </>
        )}
        <Autocomplete
          options={countries}
          disableClearable
          MenuProps={{
            style: { zIndex: 10000000 },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          getOptionLabel={option => option.country}
          value={
            countries.find(country => country.code === account.country) || null
          }
          onChange={handleCountryChange}
          className="countryDropdown"
          renderInput={params => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              error={!!errors.country}
              style={{ zIndex: 0 }}
              helperText={errors.country}
              label={translate("account_my_profile_country")}
              fullWidth
              className="mt-3"
              minLength={3}
            />
          )}
          renderOption={(props, option) => (
            <MenuItem
              className="country-item"
              disablePortal
              key={option.code}
              value={option.code}
              {...props}
            >
              <span>{option.country}</span>
            </MenuItem>
          )}
        />
        <MuiPhone
          className="mt-3"
          InputLabelProps={{ shrink: true }}
          autoComplete="false"
          error={errors.phone}
          helperText={errors.phone}
          onChange={e =>
            setAccount({
              ...account,
              phone: e,
            })
          }
          label={translate("account_my_settings_profile_phone")}
          fullWidth
          required
          minLength={3}
        />
        <TextField
          autoComplete="first-name"
          style={{ zIndex: 0 }}
          onChange={e =>
            setAccount({
              ...account,
              firstName: e.target.value,
            })
          }
          label={translate("auth_register_firstName")}
          error={errors.firstName}
          helperText={errors.firstName}
          className="mt-3"
          fullWidth
          aria-describedby="emailHelp"
          minLength={3}
        />
        <TextField
          autoComplete="family-name"
          style={{ zIndex: 0 }}
          className="mt-3"
          error={errors.lastName}
          helperText={errors.lastName}
          onChange={e =>
            setAccount({
              ...account,
              lastName: e.target.value,
            })
          }
          label={translate("auth_register_lastName")}
          fullWidth
          aria-describedby="emailHelp"
          minLength={3}
        />
        {errors?.conditions ? (
          <small style={{ color: "#d32f2f", fontSize: "12px" }}>
            {errors.conditions}
          </small>
        ) : null}
        <div className="mt-2">
          {!account.loading ? (
            <Button
              onClick={handleAccount}
              variant="contained"
              fullWidth
              className="rounded mt-md-3 mt-1 p-3"
            >
              {translate("auth_register_button")}
            </Button>
          ) : (
            <Button
              onClick={handleAccount}
              variant="contained"
              fullWidth
              disabled
              className="rounded mt-1 mt-md-3 p-3"
            >
              <CircularProgress size={26} />
            </Button>
          )}
        </div>
      </Form>
    </>
  )
}

export default RegisterForm

const Form = styled.div`
  width: 100%;
  max-width: 380px;
  padding: 1.75rem 1.75rem 2.5rem;
  position: relative;

  h1 {
    font-weight: 500;
    font-size: 1.4rem;
  }

  h2 {
    margin: 2rem 0;
    font-weight: 500;
    font-size: 1.4rem;
  }

  .details {
    font-size: 0.875rem;
    color: #8594a2;
    margin: 0;
  }

  .disclaimer {
    font-size: 14px;
    color: #8594a2;
  }
`
