import RegisterForm from "@/components/common/auth/RegisterForm/RegisterForm"
import { useEffect, useState } from "react"
import IntroForm from "@/components/common/auth/RegisterForm/introForm"
import { useSession } from "next-auth/react"
import { useRouter } from "next-translate-routes"

function RegisterWidget({ setRegisterModal }) {
  const [account, setAccount] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    loading: false,
    conditions: false,
    news: false,
    created: false,
    token: localStorage?.getItem("referral") || "",
    step: "intro",
  })

  const { data: session } = useSession()

  const router = useRouter()

  useEffect(() => {
    if (session) {
      setRegisterModal(false)

      router.push("/listings")
    }
  }, [session])

  return (
    <>
      {account.step === "intro" ? (
        <div className="ms-auto me-auto">
          <IntroForm
            account={account}
            setAccount={setAccount}
            setRegisterModal={setRegisterModal}
          />
        </div>
      ) : (
        <div className="ms-auto me-auto ">
          <RegisterForm
            account={account}
            setAccount={setAccount}
            setRegisterModal={setRegisterModal}
          />
        </div>
      )}
    </>
  )
}

export default RegisterWidget
