import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from "@mui/material"
import axios from "axios"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useState } from "react"
import { toast } from "react-toastify"
import styled from "styled-components"
import { signIn as signIn, useSession } from "next-auth/react"
import translate from "@/utils/translation"
import googleIcon from "@/public/icons/google.svg"
import TagManager from "react-gtm-module"
import {
  ArrowRight,
  Check,
  ChevronRight,
  Gift,
  Lock,
  X,
} from "react-bootstrap-icons"
import { FaLock } from "react-icons/fa"
import posthog from "posthog-js"
import { type } from "jquery"

const IntroForm = ({ account, setAccount, setRegisterModal }) => {
  const Router = useRouter()

  const [errors, setErrors] = useState({})
  const { data: session } = useSession()

  const translation = {
    auth_required: translate("auth_validation_required"),
    auth_email: translate("login_label_error_email"),
    auth_password: translate("login_label_error_password"),
    auth_validation_already_exist: translate("auth_validation_already_exist"),
    auth_validation_error: translate("auth_validation_error"),
    auth_new_account_succesfull: translate("auth_new_account_succesfull"),
    auth_agree_terms_1: translate("auth_agree_terms_1"),
    auth_agree_terms_2: translate("auth_agree_terms_2"),
    auth_token_wrong: translate("auth_token_wrong"),
  }

  const validate = () => {
    let temp = {}

    temp.email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      account.email
    )
      ? ""
      : translation.auth_email
    temp.password = account.password.length > 8 ? "" : translation.auth_password

    temp.conditions =
      account?.conditions === true ? "" : translation.auth_required

    setErrors({
      ...errors,
      ...temp,
    })

    return Object.values(temp)
  }

  const router = useRouter()

  const handleAccount = async () => {
    try {
      setAccount({ ...account, loading: true })
      const errorsFound = validate()

      if (errorsFound.some(item => item.length > 0) || errors.token) {
        setAccount({
          ...account,
          loading: false,
          error: translation.auth_validation_error,
        })
        return
      }

      if (account.email.length > 0 && account.password.length > 0) {
        try {
          const res = await signIn("credentials", {
            redirect: false,
            username: account.email,
            password: account.password,
          })

          if (res?.status === 200) {
            toast.success(translation.login_succesful_title, {
              position: "bottom-right",
            })

            posthog.identify(res?.data?.uid, {
              email: account?.email,
            })

            posthog.capture("sign_in", {
              email: account?.email,
            })

            TagManager.dataLayer({
              dataLayer: {
                event: "login_success",
                user_id: res?.data?.uid,
                email: account?.email,
              },
            })

            if (setRegisterModal) {
              setRegisterModal(false)
            }

            await router.push("/account/balance")
            return
          }
        } catch (error) {
          console.log(error)
        }
      }

      const response = await axios.get(`/api/users?email=${account?.email}`)

      if (response?.data?.exists === true) {
        setErrors({
          email: translation.auth_validation_already_exist,
        })
        setAccount({
          ...account,
          loading: false,
        })
        toast.error(translation.auth_validation_already_exist)
      } else {
        setAccount({
          ...account,
          step: "register",
          loading: false,
        })

        posthog.identify(account?.email, {
          email: account?.email,
          discount: account?.token,
        })

        posthog.capture("pre_sign_up", {
          email: account?.email,
          discount: account?.token,
        })

        TagManager.dataLayer({
          dataLayer: {
            event: "pre_sign_up",
            email: account?.email,
          },
        })
      }
    } catch (error) {
      console.log(error)
      setAccount({ ...account, loading: false })
    }

    window.scrollTo(0, 0)
  }

  if (session?.profile) {
    return (
      <div className="text-center w-100">
        <CircularProgress size={36} />
      </div>
    )
  }

  const handleCode = () => {
    if (account.token?.length > 0) {
      setAccount({
        ...account,
        tokenLoader: true,
      })
      setErrors({
        ...errors,
        token: "",
      })
      axios
        .get(`/api/discounts?code=${account.token}`)
        .then(res => {
          if (res?.data?.type === "referral") {
            setAccount({
              ...account,
              token: res?.data?.code,
              discountType: "referral",
              tokenLoader: false,
            })
          } else if (res?.data?.type === "discount") {
            setAccount({
              ...account,
              token: res?.data?.code,
              discountType: "discount",
              tokenLoader: false,
            })
          } else {
            setAccount({
              ...account,
              tokenLoader: false,
            })
          }
        })
        .catch(() => {
          setErrors({
            ...errors,
            token: translation.auth_token_wrong,
          })
          setAccount({
            ...account,
            tokenLoader: false,
          })
        })
    }
  }

  return (
    <>
      <Form
        className="bg-white rounded form"
        // onKeyDown={e => (e.key === "Enter" ? handleAccount() : null)}
      >
        {Router.pathname.includes("/auth/") ? (
          <div className="d-flex">
            <h1 className="mt-2 mb-1">{translate("auth_register_title")}</h1>
            <FaLock size={12} className="mt-3 ms-2" color="rgb(0, 166, 147)" />
          </div>
        ) : (
          <>
            <h2 className="mt-2 mb-1">
              {translate("auth_register_title_widget")}
            </h2>
          </>
        )}
        <>
          <TextField
            autoComplete="email"
            error={errors.email ? true : false}
            helperText={errors.email}
            style={{ zIndex: 0 }}
            InputLabelProps={{ shrink: account?.email ? true : false }}
            onChange={e =>
              setAccount({
                ...account,
                email: e.target.value,
              })
            }
            label={translate("auth_register_email")}
            fullWidth
            value={account?.email}
            aria-describedby="emailHelp"
            minLength={3}
            className="mt-3"
          />
          <TextField
            autoComplete="new-password"
            error={errors.password ? true : false}
            helperText={errors.password}
            style={{ zIndex: 0 }}
            onChange={e =>
              setAccount({
                ...account,
                password: e.target.value,
              })
            }
            type="password"
            label={translate("auth_register_password")}
            fullWidth
            minLength={8}
            className="mt-3"
          />
          <TextField
            autoComplete="referral"
            value={account?.token}
            style={{ zIndex: 0 }}
            helperText={errors.token}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Gift
                    color={
                      errors.token
                        ? "red"
                        : account.token?.length > 0
                        ? "#FA8503"
                        : "#d2d5d9"
                    }
                    size={15}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {account?.tokenLoader ? (
                    <CircularProgress size={20} />
                  ) : errors.token ? (
                    <p
                      className="error mt-3"
                      onClick={() => {
                        setAccount({
                          ...account,
                          token: "",
                        }),
                          setErrors({
                            ...errors,
                            token: "",
                          })
                      }}
                    >
                      <X size={30} color="red" />
                    </p>
                  ) : account?.token?.length > 0 ? (
                    <Check
                      color={account.token?.length > 0 ? "#FA8503" : "#d2d5d9"}
                      size={30}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "0",
                  paddingLeft: "0",
                },
              },
              "& .MuiFormLabel-root": {
                color: "#d2d5d9", // gray text color for label
              },
              "& .MuiInputBase-input": {
                color: errors.token
                  ? "red"
                  : account.token?.length > 0
                  ? "#FA8503"
                  : "#d2d5d9", // gray text color for input
                fontWeight: 600,
              },
              "& .MuiFormHelperText-root": {
                color: errors.token ? "red" : "#d2d5d9",
                marginTop: "-10px",
              },
            }}
            onChange={e => {
              setAccount({
                ...account,
                token: e.target.value,
              })

              if (e.target.value?.length === 0) {
                setErrors({
                  ...errors,
                  token: "",
                })
              }
            }}
            label={
              <div className="d-flex">
                {translate("register_referral")}
                <span className="ms-1 smaller">
                  ({translate("register_referral_optional")})
                </span>
              </div>
            }
            onBlur={e => handleCode()}
            variant="outlined"
            fullWidth
            minLength={8}
            className={"mt-4"}
          />
          <FormGroup className="p-0">
            <FormControlLabel
              onChange={e => {
                setAccount({
                  ...account,
                  conditions: e.target.checked,
                }),
                  setErrors({
                    ...errors,
                    conditions: "",
                  })
              }}
              checked={account?.conditions}
              control={<Checkbox />}
              label={
                <div className="conditions">
                  <p>
                    {translate("auth_agree_terms_1")}
                    <Link
                      href="/policy/privacy-policy"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="condition"
                    >
                      {translate("auth_agree_terms_2")}
                    </Link>
                    {" " + translate("auth_agree_terms_3") + " "}
                    <Link
                      href="/policy/user-terms"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="condition"
                    >
                      {translate("auth_agree_terms_4")}
                    </Link>
                  </p>
                </div>
              }
            />
            {errors.conditions && (
              <p className="error-text">{errors.conditions}</p>
            )}
          </FormGroup>
          {account.loading === true ? (
            <Button
              disabled
              variant="contained"
              fullWidth
              className="rounded mt-3 p-3"
            >
              <CircularProgress size={24} />
            </Button>
          ) : account.email?.length < 5 || account.password.length < 8 ? (
            <Button
              onClick={handleAccount}
              variant="contained"
              fullWidth
              disabled
              className="rounded mt-3 p-3"
            >
              {translate("auth_register_button")}
            </Button>
          ) : (
            <Button
              onClick={handleAccount}
              onKeyDown={
                account.email?.length > 5 && account.password.length > 8
                  ? e => (e.key === "Enter" ? handleAccount() : null)
                  : null
              }
              variant="contained"
              fullWidth
              className="rounded mt-3 p-3"
            >
              {translate("auth_register_button")}
            </Button>
          )}
        </>
        <p className="company link">
          {translate("auth_register_as_company")}
          <Link href="/auth/register/business" className="ms-1">
            {translate("auth_register_as_company_2")}
          </Link>
        </p>
      </Form>
    </>
  )
}

export default IntroForm

const Form = styled.div`
  width: 100%;
  max-width: 390px;
  position: relative;
  padding: 1.5rem 1.75rem 1rem;

  .conditions p {
    font-weight: 400;
    font-size: 0.85rem;
    color: rgb(104, 123, 141);
    margin: 0.5rem 0 0.5rem 0;

    a {
      color: rgb(104, 123, 141);
      text-decoration: underline;
      font-size: 0.85rem;
      font-weight: 400;
    }
  }

  h1 {
    margin: 2rem 0;
    font-weight: 500;
    font-size: 1.2rem;
  }

  h2 {
    margin: 2rem 0;
    font-weight: 500;
    font-size: 1.25rem !important;
  }

  .condition {
    font-weight: 400;
    font-size: 12px;
  }

  .divider {
    margin: 1rem 0;
    font-size: 12px;
    font-weight: 400;
    color: #707070;
  }

  .provider {
    padding: 0.85rem 2rem 0.85rem 1rem;
    border-radius: 5px;
    border: 1px solid #ebebeb;

    .google {
      margin-top: 2px;
      background-image: url(${googleIcon.src});
      width: 20px;
      height: 20px;
    }
    .linkedin {
      margin-top: 2px;
    }

    .text {
      margin-left: 0.75rem;
    }
  }

  .error-text {
    color: red;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }

  .link {
    font-size: 0.875rem;
    width: 100%;
    max-width: 500px;
    margin-top: 1rem;
    color: #707070;

    a {
      color: #fb8501;
      text-decoration: none;
    }
  }

  .smaller {
    font-size: 0.7rem;
    margin-top: 0rem;
  }

  .referral .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
`
