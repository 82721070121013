import { Context } from "@/context/state"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ChevronDown, ChevronUp, Globe } from "react-bootstrap-icons"
import { useSession } from "next-auth/react"
import axios from "axios"
import { reloadSession } from "@/services/common/helpers"
import { ClickAwayListener } from "@mui/material"

const LanguageDropdown = () => {
  const [show, setShow] = useState(false)

  const { data: session } = useSession()

  const router = useRouter()

  const { state, dispatch } = useContext(Context)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const sessionLocale = session?.profile?.locale
      const currentLocale = state.locale
      const routerLocale = router?.locale

      // Update state.locale if it doesn't match sessionLocale
      if (sessionLocale && currentLocale !== sessionLocale) {
        dispatch({
          type: "SET_LOCALE",
          payload: sessionLocale,
        })
        return
      }

      // Update router locale if it doesn't match currentLocale
      if (currentLocale && routerLocale !== currentLocale) {
        router.push(router.asPath, undefined, {
          locale: currentLocale,
        })
        return
      }

      // Initialize state.locale if it's not set
      if (!currentLocale) {
        dispatch({
          type: "SET_LOCALE",
          payload: sessionLocale || routerLocale,
        })
      }
    }
  }, [session?.profile?.locale, state.locale, router.locale])

  const updateUserLocale = async locale => {
    if (session) {
      const res = await axios
        .put("/api/users/locale", locale, {
          headers: {
            Authorization: `bearer ${session.jwt}`,
          },
        })
        .catch(error => {
          console.log(error)
        })

      if (res.status === 200) {
        reloadSession()

        dispatch({
          type: "SET_LOCALE",
          payload: locale.locale,
        })
      }
    }
  }

  return (
    <>
      <Item
        className="clickable d-flex justify-content-start"
        onClick={() => {
          setShow(!show)
        }}
      >
        <div className="d-flex">
          <Globe className="me-2 mt-1" color="#8f8f8f" />
          <span className="country-name">
            {state?.locale === "en"
              ? "English"
              : state?.locale === "nl"
              ? "Nederlands"
              : state?.locale === "es"
              ? "Español"
              : state?.locale === "de"
              ? "Deutsch"
              : router?.locale === "en"
              ? "English"
              : router?.locale === "fr"
              ? "Français"
              : "Nederlands"}
          </span>
        </div>
      </Item>
      <div
        className="position-relative"
        onClick={() => {
          setShow(!show)
        }}
      >
        {show && (
          <ClickAwayListener onClickAway={() => setShow(false)}>
            <Language>
              <div className="active clickable">
                {state?.locale === "en"
                  ? "English"
                  : state?.locale === "nl"
                  ? "Nederlands"
                  : state?.locale === "es"
                  ? "Español"
                  : state?.locale === "de"
                  ? "Deutsch"
                  : router?.locale === "en"
                  ? "English"
                  : router?.locale === "fr"
                  ? "Français"
                  : "Nederlands"}
              </div>
              {router.locales
                .filter(item => item !== state?.locale)
                .map((locale, i) => (
                  <div
                    key={i}
                    className="clickable"
                    onClick={() => {
                      setShow(false),
                        dispatch({
                          type: "SET_LOCALE",
                          payload: locale,
                        }),
                        updateUserLocale({ locale: locale })
                    }}
                  >
                    {locale === "en"
                      ? "English"
                      : locale === "es"
                      ? "Español"
                      : locale === "de"
                      ? "Deutsch"
                      : locale === "fr"
                      ? "Français"
                      : "Nederlands"}
                  </div>
                ))}
            </Language>
          </ClickAwayListener>
        )}
      </div>
    </>
  )
}

export default LanguageDropdown

const Language = styled.div`
  position: absolute;
  left: 10px;
  z-index: 9999;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.18);
  background: white;
  border-radius: 5px;

  div {
    padding: 0.875rem 1.25rem !important;
    font-size: 0.875rem;

    :hover {
      color: grey;
    }
  }

  .active {
    border-bottom: 1px solid #ddd;
    font-weight: 500;
  }
`

const Item = styled.div`
  margin-top: 1.5rem;

  .nav-item {
    :first-child {
      margin-left: 4rem;
    }
    padding: 1rem;
    padding-right: 3rem;
    margin: auto 0;
  }

  font-weight: 300;

  color: #8f8f8f;
  border-radius: 20px;
  font-size: 0.875rem;
`
