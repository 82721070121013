import { Context } from "@/context/state"
import { useRouter } from "next/router"
import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { ChevronDown, Globe } from "react-bootstrap-icons"
import { useSession } from "next-auth/react"
import axios from "axios"

const MobileLanguage = ({ ref, isComponentVisible, setIsComponentVisible }) => {
  const router = useRouter()

  const { state, dispatch } = useContext(Context)

  const { data: session } = useSession()

  useEffect(() => {
    if (state.locale === undefined) {
      dispatch({
        type: "SET_LOCALE",
        payload: router.locale,
      })
    } else if (state.locale !== router.locale) {
      router.push(router.asPath, router.asPath, { locale: state.locale })
    }
  })

  const updateUserLocale = async locale => {
    if (session) {
      const res = await axios
        .put("/api/users/locale", locale, {
          headers: {
            Authorization: `bearer ${session.jwt}`,
          },
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <Item
        className=" clickable country d-flex"
        ref={ref}
        onClick={() => {
          setIsComponentVisible(!isComponentVisible)
        }}
      >
        <Globe className="me-2 globe" />
        <div className="pb-3">
          {state?.locale?.toUpperCase() || router.locale?.toUpperCase()}
        </div>
        <ChevronDown size={10} className="ms-2 mt-2 chevron" />
      </Item>
      <div className="position-relative" ref={ref}>
        {isComponentVisible && (
          <Language>
            <div
              className="active clickable"
              onClick={() => {
                setIsComponentVisible(false)
              }}
            >
              {state?.locale === "en"
                ? "English"
                : state?.locale === "nl"
                ? "Nederlands"
                : state?.locale === "es"
                ? "Español"
                : state?.locale === "de"
                ? "Deutsch"
                : router?.locale === "en"
                ? "English"
                : router?.locale === "fr"
                ? "Français"
                : "Nederlands"}
            </div>
            {router.locales
              .filter(item => item !== state?.locale && item !== router.locale)
              .map(locale => (
                <div
                  className="clickable"
                  onClick={() => {
                    setIsComponentVisible(false),
                      dispatch({
                        type: "SET_LOCALE",
                        payload: locale,
                      }),
                      updateUserLocale({ locale: locale })
                  }}
                >
                  {locale === "en"
                    ? "English"
                    : locale === "es"
                    ? "Español"
                    : locale === "de"
                    ? "Deutsch"
                    : locale === "fr"
                    ? "Français"
                    : "Nederlands"}
                </div>
              ))}
          </Language>
        )}
      </div>
    </>
  )
}

export default MobileLanguage

const Language = styled.div`
  position: absolute;
  z-index: 9999;
  right: 0px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.18);
  background: white;
  border-radius: 5px;

  div {
    padding: 0.875rem 1.25rem !important;
    font-size: 0.875rem;

    :hover {
      color: grey;
    }
  }

  .active {
    border-bottom: 1px solid #ddd;
    font-weight: 500;
  }
`

const Item = styled.div`
  margin-top: 0.1rem;

  .nav-item {
    :first-child {
      margin-left: 4rem;
    }
    padding-right: 3rem;
    margin: auto 0;
  }

  justify-content: center;
`
