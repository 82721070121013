const Line = () => (
  <svg height="1" width="100%">
    <line
      stroke="#A5ACB3"
      strokeWidth="1"
      strokeDasharray="1, 4"
      strokeLinecap="round"
      x1="0"
      y1="0.5"
      x2="100%"
      y2="0.5"
    ></line>
  </svg>
)

export default Line
