import { Linkedin, Youtube } from "react-bootstrap-icons"
import translate from "@/utils/translation"
import moment from "moment"
import { Link } from "next-translate-routes"
import React from "react"
import styled from "styled-components"
import TagManager from "react-gtm-module"
import { BiLogoInstagramAlt } from "react-icons/bi"

const Footernote = ({ session }) => {
  return (
    <Note className="container">
      <div className="d-flex footer-note justify-content-between flex-wrap">
        <div className="items d-none d-md-flex">
          <div className="item copyright">
            ©{moment().year()} Valvest - {translate("rights_reserved")}
          </div>
          |
          <div className="item">
            <Link
              onClick={() =>
                TagManager.dataLayer({
                  dataLayer: {
                    event: "footer_terms",
                    user_id: session?.uid,
                  },
                })
              }
              href="/policy/privacy-policy"
            >
              {translate("privacy_statement")}
            </Link>
          </div>
          |
          <div className="item">
            <Link
              onClick={() =>
                TagManager.dataLayer({
                  dataLayer: {
                    event: "footer_terms",
                    user_id: session?.uid,
                  },
                })
              }
              href="/policy/user-terms"
            >
              {translate("terms_conditions")}
            </Link>
          </div>
          |
          <div className="item">
            <Link
              onClick={() =>
                TagManager.dataLayer({
                  dataLayer: {
                    event: "footer_terms",
                    user_id: session?.uid,
                  },
                })
              }
              href="/policy/anti-money-laundering"
            >
              {translate("terms_conditions_AML")}
            </Link>
          </div>
        </div>
        <div className="items d-md-none mb-3">
          <div className="item copyright  p-0">
            ©{moment().year()} Valvest - {translate("rights_reserved")}
          </div>
          <div className="d-flex justify-content-center">
            <div className="item  pe-2 ps-2">
              <Link
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "footer_terms",
                      user_id: session?.uid,
                    },
                  })
                }
                href="/policy/privacy-policy"
              >
                {translate("privacy_statement")}
              </Link>
            </div>
            <div className="ps-1 pe-1">|</div>
            <div className="item  pe-2 ps=2">
              <Link
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "footer_terms",
                      user_id: session?.uid,
                    },
                  })
                }
                href="/policy/user-terms"
              >
                {translate("terms_conditions")}
              </Link>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start socials">
          <a target="_blank" href="http://linkedin.com/company/valvest-capital">
            <Linkedin size="20px" />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCBTrSn98qX6RM38IjLehW7Q"
          >
            <Youtube size="24px" style={{ marginTop: "-1px" }} />
          </a>
          <a target="_blank" href="https://instagram.com/valvest.io">
            <BiLogoInstagramAlt size="24px" style={{ marginTop: "-2px" }} />
          </a>
        </div>
      </div>
      <div className="disclaimer" data>
        <p>{translate("footer_disclaimer_1")}</p>
        <p>{translate("footer_disclaimer_2")}</p>
        <p>{translate("footer_disclaimer_3")}</p>
      </div>
    </Note>
  )
}

export default Footernote

const Note = styled.div`
  background-color: white;

  h5 {
    font-size: 1rem;
  }

  ul {
    line-height: 2.25rem;
    li a {
      font-size: 0.8rem;
      text-decoration: none;
      color: #17233b;
      font-weight: 300;
    }
  }

  p {
    margin-top: 0;
    font-size: 0.8rem;
    font-weight: 300;
  }

  .signup {
    font-size: 12px;
    padding: 0.3rem 0.2rem;
  }

  .footer-note {
    font-size: 0.75rem;
    color: #8f8f8f;
    margin-top: 1.5rem;
  }

  .items {
    @media (max-width: 800px) {
      width: 100%;
      justify-content: center;
      padding-bottom: 1rem;

      .item {
        text-align: center;
      }
    }
  }

  .copyright {
    @media (max-width: 800px) {
      width: 100%;
      justify-content: center;
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }

  .item {
    padding: 0 1rem;
    a {
      font-size: 0.75rem;
      text-decoration: none;
      color: #8f8f8f;
    }

    :first-child {
      padding-left: 0;
    }
  }

  .socials {
    a {
      color: #17233b;
    }
    gap: 20px;
    cursor: pointer;

    @media (max-width: 800px) {
      justify-content: center !important;
      width: 100%;
      text-align: center;
    }
  }

  .contact p {
  }

  .address {
    a {
      color: #17233b;
      text-decoration: none;
    }

    p {
      line-height: 1rem;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    b {
      font-size: 1rem;
    }

    .icon {
      background-color: #fff0e0;
      padding: 1px 8px 3px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }

  h5 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  hr {
    margin-top: 3rem;
    background-color: #c3c3c3;
  }

  .email {
    border: 1px solid lightgray;
    padding: 0.4rem;
    border-radius: 50px;
    max-width: 210px;

    .icon {
      background-color: #fff0e0;

      svg {
        color: #fb8500;
      }

      padding: 1px 8px 3px;
      border-radius: 50%;
    }

    p {
      margin: 4px 0 0 0;

      a {
        padding: 0 20px;
        color: #17233b;
        text-decoration: none;
      }
    }
  }

  .disclaimer {
    color: #8f8f8f;
    margin-top: 1.5rem;

    p {
      font-size: 0.75rem !important;
    }

    @media (max-width: 800px) {
      padding: 1rem;
    }
  }
`
